export default [
  {
    teamName: "TOTTENHAM HOTSPUR FC",
    manager: { firstName: "RYAN", lastName: "MASON" },
    bench: [
      { firstName: "PAULO", lastName: "GAZZANIGA", isGk: true },
      { firstName: "DAVINSON", lastName: "SANCHEZ", isGk: false },
      { firstName: "SERGE", lastName: "AURIER", isGk: false },
      { firstName: "HARRY", lastName: "WINKS", isGk: false },
      { firstName: "GIOVANI", lastName: "LO CELSO", isGk: false },
      { firstName: "STEVEN", lastName: "BERGWIJN", isGk: false },
      { firstName: "LUCAS", lastName: "MOURA", isGk: false },
    ],
    players: [
      {
        position: "GK",
        firstName: "HUGO",
        lastName: "LLORIS",
        number: 1,
        isCaptain: true,
      },
      {
        position: "CB",
        firstName: "ERIK",
        lastName: "DIER",
        number: 15,
        isCaptain: false,
      },
      {
        position: "CB",
        firstName: "TOBY",
        lastName: "ALDERWEIRELD",
        number: 4,
        isCaptain: false,
      },
      {
        position: "RB",
        firstName: "MATT",
        lastName: "DOHERTY",
        number: 2,
        isCaptain: false,
      },
      {
        position: "LB",
        firstName: "SERGIO",
        lastName: "REGUILLON",
        number: 3,
        isCaptain: false,
      },
      {
        position: "CM",
        firstName: "PIERRE-EMILE",
        lastName: "HOJBJERG",
        number: 5,
        isCaptain: false,
      },
      {
        position: "RCM",
        firstName: "MOUSSA",
        lastName: "SISSOKO",
        number: 17,
        isCaptain: false,
      },
      {
        position: "LCM",
        firstName: "TANGUY",
        lastName: "NDOMBELE",
        number: 28,
        isCaptain: false,
      },
      {
        position: "RF",
        firstName: "GARETH",
        lastName: "BALE",
        number: 11,
        isCaptain: false,
      },
      {
        position: "LF",
        firstName: "HEUNG-MIN",
        lastName: "SON",
        number: 7,
        isCaptain: false,
      },
      {
        position: "CF",
        firstName: "HARRY",
        lastName: "KANE",
        number: 10,
        isCaptain: false,
      },
    ],
  },
  {
    teamName: "MANCHESTER CITY",
    manager: { firstName: "PEP", lastName: "GUARDIOLA" },
    bench: [
      { firstName: "ZACK", lastName: "STEFFEN", isGk: true },
      { firstName: "NATHAN", lastName: "AKE", isGk: false },
      { firstName: "AYMERIC", lastName: "LAPORTE", isGk: false },
      { firstName: "", lastName: "FERNANDINHO", isGk: false },
      { firstName: "PHIL", lastName: "FODEN", isGk: false },
      { firstName: "BERNARDO", lastName: "SILVA", isGk: false },
      { firstName: "SERGIO", lastName: "AGUERO", isGk: false },
    ],
    players: [
      {
        position: "GK",
        firstName: "",
        lastName: "EDERSON",
        number: 31,
        isCaptain: false,
      },
      {
        position: "RB",
        firstName: "KYLE",
        lastName: "WALKER",
        number: 2,
        isCaptain: false,
      },
      {
        position: "CB",
        firstName: "JOHN",
        lastName: "STONES",
        number: 5,
        isCaptain: false,
      },
      {
        position: "GK",
        firstName: "RUBEN",
        lastName: "DIAZ",
        number: 3,
        isCaptain: false,
      },
      {
        position: "LB",
        firstName: "JOAO",
        lastName: "CANCELO",
        number: 27,
        isCaptain: false,
      },
      {
        position: "CM",
        firstName: "",
        lastName: "RODRI",
        number: 16,
        isCaptain: false,
      },
      {
        position: "RCM",
        firstName: "ILKAY",
        lastName: "GUNDOGAN",
        number: 8,
        isCaptain: false,
      },
      {
        position: "LCM",
        firstName: "KEVIN",
        lastName: "DE BRUYNE",
        number: 17,
        isCaptain: true,
      },
      {
        position: "RF",
        firstName: "RIYAD",
        lastName: "MAHREZ",
        number: 26,
        isCaptain: false,
      },
      {
        position: "LF",
        firstName: "RAHEEM",
        lastName: "STERLING",
        number: 7,
        isCaptain: false,
      },
      {
        position: "CF",
        firstName: "GABRIEL",
        lastName: "JESUS",
        number: 9,
        isCaptain: false,
      },
    ],
  },
];
