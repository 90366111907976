import React from "react";

const TeamLogo = () => (
  <div className="crest">
    {/* <img className="team-logo" src={logo} alt="team logo" /> */}
  </div>
);
TeamLogo.propTypes = {};

export default TeamLogo;
